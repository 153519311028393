import React, { Component } from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import { TranslatedBlock } from 'src/components/language';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setPage('privacy-policy');
  }

  render() {
    const title = {
      english: 'Privacy Statement'
    };

    const showNavbar = this.context.section === 'patient';

    return (
      <Layout title={title} showNavbar={showNavbar} className="patient">
        <section className="container my-5">
          <div className="row">
            <div className="col-12 pb-3">
              <TranslatedBlock language="english">
                <h1 className="ornament ornament-header">Privacy Statement</h1>
              </TranslatedBlock>
            </div>
            <div className="col-12 pb-3 os-font">
              <TranslatedBlock language="english">
                <p>HOFFMANN-LA ROCHE LIMITED (Roche Canada) Online Privacy Statement</p>
                <p>
                  Thank you for visiting a Roche Web site or interacting with us via e-mail and other social media
                  sites.
                </p>
                <p>
                  At Roche, we understand that protecting the privacy of visitors to our Web sites is very important and
                  that information about your health is particularly sensitive. That is why we have taken the necessary
                  steps to meet worldwide data privacy requirements. We treat your “personal data” according to the ”
                  Roche Directive on the Protection of Personal Data”, and to the laws of Canada, the EU, Switzerland
                  and other applicable local laws which regulate the storage, processing, access and transfer of
                  personal data.
                </p>
                <p>
                  Roche Web sites that display this privacy statement (“Statement”) and ask for any information from you
                  are committed to collecting, maintaining, and securing personal information about you in accordance
                  with this Statement, as well as applicable laws, rules and regulations. This Statement applies to
                  personal information (as defined below) collected from Roche online resources and communications (such
                  as Web sites, e-mail, and other online tools) that display a link to this Statement. This Statement
                  does not apply to personal information collected from offline resources and communications, except in
                  cases where such personal information is consolidated with personal information collected by Roche
                  online. This Statement also does not apply to third-party online resources to which Roche’s Web sites
                  may link, where Roche does not control the content or the privacy practices of such resources.
                </p>
                <p>
                  We only collect personally identifiable information about you if you choose to give it to us. We do
                  not share any of your personally identifiable information with third parties for their own marketing
                  use unless you explicitly give us permission to do so. Please review this Privacy Statement to learn
                  more about how we collect, use, share and protect information online.
                </p>
                <p>
                  Please be aware that we may use service providers and data processors working on our behalf. The
                  services can include hosting and maintenance services, analysis services, e-mail messaging services,
                  delivery services, handling of payment transactions, solvency check and address check, etc. These
                  third parties are granted access to such personal information they require in order to be able to
                  carry out the particular service. The service providers and data processors are contractually obliged
                  to treat such information in the strictest confidence. It is also contractually forbidden for them to
                  use the information in any other way than required. The necessary steps are taken to ensure that our
                  service providers and the processors working on our behalf protect the confidentiality of your
                  personal information. Where personal information is stored or processed outside of Canada (whether by
                  us or a service provider or data processor as outlined above), it is subject to the laws of that
                  foreign jurisdiction, and maybe accessible to that jurisdiction’s government, courts, or law
                  enforcement or regulatory agencies.
                </p>
                <p>
                  <u>Information Collected</u>
                </p>
                <p>There are two general methods that Roche uses to collect information from you online:</p>
                <p>
                  <u>Information We Get</u>
                </p>
                <p>
                  <i>
                    - Personal identifiable information: You can visit our Web sites without providing any personal
                    information. We may collect your personally identifiable information (such as name, address,
                    telephone number, e-mail address or other identifying information) only when you choose to submit it
                    to us. We may also collect health information about you that you provide by responding to our
                    questions or surveys.
                  </i>
                </p>
                <p>
                  <i>
                    - Aggregate information: In some cases we also remove personal identifiers from data you provide to
                    us and maintain it in aggregate form. We may combine this data with other information to produce
                    anonymous, aggregated statistical information (e.g. number of visitors, originating domain name of
                    the Internet Service Provider), helpful to us in improving our products and services.
                  </i>
                </p>
                <p>
                  <u>Automatically Collected Information</u>
                </p>
                <p>
                  We and some third party providers we work with automatically receive certain types of information
                  whenever you interact with us on our sites and in some e-mails we may send each other. Automatic
                  technologies and services we use may include, for example, Web server logs/IP addresses, cookies, Web
                  beacons and third party application and content tools.
                </p>
                <p>
                  <i>Web Server Logs/IP Addresses</i>. An IP address is a number assigned to your computer whenever you
                  access the Internet. All computer identification on the Internet is conducted with IP addresses, which
                  allow computers and servers to recognize and communicate with each other. Roche collects IP addresses
                  to conduct system administration and report aggregate information to affiliates, business partners
                  and/or vendors to conduct site analysis and Web site performance review.
                </p>
                <p>
                  <i>Cookies</i>. A cookie is a piece of information that is placed automatically on your computer’s
                  hard drive when you access certain Web sites. The cookie uniquely identifies your browser to the
                  server. Cookies allow us to store information on the server to help make the Web experience better for
                  you and to conduct site analysis and Web site performance review. Most Web browsers are set up to
                  accept cookies, although you can reset your browser to refuse all cookies or to indicate when a cookie
                  is being sent. Note, however, that some portions of our sites may not work properly if you refuse
                  cookies.
                </p>
                <p>
                  <i>Web Beacons</i>. On certain Web pages or e-mails, Roche may utilize a common Internet technology
                  called a "Web beacon" (also known as an "action tag" or "clear GIF technology"). Web beacons help
                  analyze the effectiveness of Web sites by measuring, for example, the number of visitors to a site or
                  how many visitors clicked on key elements of a site.
                </p>
                <p>
                  Web beacons, cookies and other tracking technologies do not automatically obtain personally
                  identifiable information about you. Only if you voluntarily submit personally identifiable
                  information, such as by registering or sending e-mails, can these automatic tracking technologies be
                  used to provide further information about your use of the Web sites and/or interactive e-mails to
                  improve their usefulness to you.
                </p>
                <p>
                  <i>Services</i>: We may provide services based on third party applications and content tools on
                  certain Roche Websites such as Google Maps or QUARTAL FLIFE. These third parties may automatically
                  receive certain types of information whenever you interact with us on our sites using such third party
                  applications and tools.
                </p>
                <p>
                  <u>Your Choices</u>
                </p>
                <p>
                  You have several choices regarding your use of our Web sites. You could decide not to submit any
                  personally identifiable information at all by not entering it into any forms or data fields on our
                  sites and not using any available personalized services. If you choose to submit personal data, you
                  have the right to see and correct your data at any time by accessing the application. Certain sites
                  may ask for your permission for certain uses of your information and you can agree to or decline those
                  uses. If you opt-in for particular services or communications, such as an e-newsletter, you will be
                  able to unsubscribe at any time by following the instructions included in each communication. If you
                  decide to unsubscribe from a service or communication, we will work to remove your information
                  promptly, although we may require additional information before we can process your request.
                </p>
                <p>
                  As described above, if you wish to prevent cookies from tracking you anonymously as you navigate our
                  sites, you can reset your browser to refuse all cookies or to indicate when a cookie is being sent.
                </p>
                <p>
                  <u>Security</u>
                </p>
                <p>
                  Roche uses technology and security precautions, rules and other procedures to protect your personal
                  information from unauthorized access, improper use, disclosure, loss or destruction. To ensure the
                  confidentiality of your information, Roche uses also industry standard firewalls and password
                  protection. It is, however, your personal responsibility to ensure that the computer you are using is
                  adequately secured and protected against malicious software, such as trojans, computer viruses and
                  worm programs. You are aware of the fact that without adequate security measures (e.g. secure web
                  browser configuration, up-to-date antivirus software, personal firewall software, no usage of software
                  from dubious sources) there is a risk that the data and passwords you use to protect access to your
                  data, could be disclosed to unauthorized third parties.
                </p>
                <p>
                  <u>Use of Data</u>
                </p>
                <p>
                  Roche, including the subsidiaries, divisions and groups worldwide and/or the companies we hire to
                  perform services on our behalf will use any personally identifiable information you choose to give us
                  to comply with your requests. We will retain control of and responsibility for the use of this
                  information. Some of this data may be stored or processed at computers located in other jurisdictions,
                  such as the United States, whose data protection laws may differ from the jurisdiction in which you
                  live. In such cases, we will ensure that appropriate protections are in place to require the data
                  processor in that country to maintain protections on the data that are equivalent to those that apply
                  in the country in which you live.
                </p>
                <p>
                  The information, which is also used for different HR purposes (Performance Management, Succession
                  decisions or Development actions), will be helpful for us to better understand your needs and how we
                  can improve our products and services. It helps us also to personalize certain communications with you
                  about services and promotions that you might find interesting. For example, we may analyze the gender
                  or age of visitors to sites about a particular medication or disease state, and we may use that
                  analysis of aggregate data internally or share it with others.
                </p>
                <p>
                  <u>Data Sharing and Transfer</u>
                </p>
                <p>
                  Roche shares personally identifiable data about you with various outside companies or agents doing
                  technological maintenance or working on our behalf to help fulfill business transactions, such as
                  providing customer services, sending marketing communications about our products, services and offers.
                  We may also share personally identifiable data with our company's subsidiaries and affiliates.
                  <p>All these companies and agents are required to comply with the terms of our privacy policies.</p>
                  We may also disclose personally identifiable information for these purposes:
                </p>
                <p>
                  a) in connection with the sale, assignment or other transfer of the business of the site to which the
                  data relates;
                </p>
                <p>
                  b) to respond to appropriate requests of legitimate government agencies or where required by
                  applicable laws, court orders, or government regulations; or
                </p>
                <p>
                  c) where needed for corporate audits or to investigate or respond to a complaint or security threat.
                </p>
                <p>
                  <i>No Third-Party Direct Marketing Use</i>. We will not sell or otherwise transfer the personally
                  identifiable information you provide to us at our Web sites to any third parties for their own direct
                  marketing use unless we provide clear notice to you and obtain your explicit consent for your data to
                  be shared in this manner.
                </p>
                <p>
                  <i>E-mail a Friend or Colleague</i>. On some Roche sites, you can choose to send a link or a message
                  to a friend or colleague referring them to a Roche Web site. E-mail addresses you may provide for a
                  friend will be used to send your friend information on your behalf and will not be collected or used
                  by Roche or other third parties for additional purposes.
                </p>
                <p>
                  <i>Google Analytics</i>. Roche Websites may use{' '}
                  <b>
                    <u>Google Analytics</u>
                  </b>
                  , a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which
                  are text files placed on your computer, to help the website analyze how users use the site. The
                  information generated by the cookie about your use of the website (including your IP address) will be
                  transmitted to and stored by Google on servers in the United States . Google will use this information
                  for the purpose of evaluating your use of the website, compiling reports on website activity for
                  website operators and providing other services relating to website activity and internet usage. Google
                  may also transfer this information to third parties where required to do so by law, or where such
                  third parties process the information on Google's behalf. Google will not associate your IP address
                  with any other data held by Google. You may refuse the use of cookies by selecting the appropriate
                  settings on your browser, however, as mentioned above please note that if you do this you may not be
                  able to use the full functionality of a Roche website. By using a Roche website, you consent to the
                  processing of data about you by Google in the manner and for the purposes set out above.
                </p>
                <p>
                  <u>Links to Other Sites</u>
                </p>
                <p>
                  Our sites contain links to a number of Web sites that may offer useful information to our visitors.
                  This Privacy Statement does not apply to those sites, and we recommend communicating to them directly
                  for information on their privacy policies.
                </p>
                <p>
                  <u>Privacy Statement for Children</u>
                </p>
                <p>
                  Our Web sites are directed at an adult audience. We do not collect any personally identifiable
                  information from anyone we know to be under the age of 13 without the prior, verifiable consent of his
                  or her legal representative. Such legal representative has the right, upon request, to view the
                  information provided by the child and/or to require that it be deleted.
                </p>
                <p>
                  <u>Note to Users of Business or Professional Web Sites</u>
                </p>
                <p>
                  If you have a business or professional relationship with Roche, we may use information you submit on
                  our sites, including sites intended specifically for business and professional users, to fulfill your
                  requests and develop our business relationship with you and the entities you represent. We may also
                  share such information with third parties acting on our behalf.
                </p>
                <p>
                  <u>Updates to Privacy Statement</u>
                </p>
                <p>
                  From time to time, Roche may revise this online Privacy Statement. Any such changes to this Privacy
                  Statement will be promptly communicated on this page. Continued use of our sites after receiving
                  notice of a change in our Privacy Statement indicates your consent to the use of newly submitted
                  information in accordance with the amended Roche Privacy Statement. The effective date of this Privacy
                  Statement is July 1, 2013.
                </p>
                <p>
                  <u>How to Contact Roche</u>
                </p>
                <p>
                  For questions or if you wish Roche to amend or delete your profile, please contact us in writing (by
                  post), as follows:
                </p>
                <p>
                  <b>Hoffmann-La Roche Ltd.</b>
                  <br />
                  (Pharma) Privacy Officer
                  <br />
                  7070 Mississauga Road
                  <br />
                  Mississauga, Ontario L5N 5M8
                </p>
                <p>
                  <b>Roche Diagnostics</b>
                  <br />
                  Division of Hoffmann-La Roche Ltd.
                  <br />
                  Privacy Officer
                  <br />
                  201 Armand-Frappier Blvd.
                  <br />
                  Laval, Quebec H7V 4A2
                </p>
              </TranslatedBlock>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default PatientPage;
